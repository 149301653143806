import type { UserProfile } from 'sima-suite-authentication'
import type { SDataSheetItem } from 'sima-suite-components-vue3'

export interface AuthenticationConfig {
  authority: string
  clientId: string
  redirectLogin: string
  redirectLogout: string
  silentRenew: string
  scope: string
}

export interface Settings {
  hotjarKey: string
  gtmKey: string
  backendUrl: string
  authentication: AuthenticationConfig
  key?: string
  app?: string
  googleAnalyticsUA?: string
  sentryKey: string
  canManageAppointment: boolean
  productFruitsWorkspaceKey?: string
  isServiceKeyVisible: boolean
}

export interface AuthenticationClient {
  getAccessToken(): string
  getUserProfile(): UserProfile
  isLoggedIn(): Promise<boolean>
  signIn(): Promise<void>
  redirect(): Promise<void>
  logout(): Promise<void>
}

export enum ProcessStatus {
  CREATED = 'created',
  ASSIGNED = 'assigned',
  EXPIRED = 'expired',
  CONTACTED = 'contacted',
  POSTPONED = 'postponed',
  SCHEDULED = 'scheduled',
  CANCELED = 'canceled'
}

export enum ProcessStatusId {
  CREATED = 0,
  ASSIGNED = 1,
  CONTACTED = 2,
  POSTPONED = 3,
  SCHEDULED = 4,
  EXPIRED = 5,
  CANCELED = 6
}

export type SDataSheetProps = {
  title: string
  items: SDataSheetItem[]
  backgroundColor?: string
  height?: string
  isLinear?: boolean
  withDivider?: boolean
  expandable?: boolean
}

type sectionsInfo = {
  name: string
  color: string
}

export type ChartSections = {
  info: sectionsInfo[]
  data: number[][]
  labels: string[]
}
