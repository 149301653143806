<template>
  <div class="pt-spacing-s">
    <div class="p-spacing-xs">
      <s-custom-list :items="items">
        <div v-if="isLoading" class="flex w-full items-center justify-center">
          <s-progress-circular :is-active="isLoading" />
        </div>
        <div v-else>
          <template v-for="lead in items" :key="lead.id">
            <s-list-item
              :overline="getOverlineText(lead)"
              :primary-text="lead.topic ? translate(`Lead.Topic.${lead.topic}`) : ''"
              :secondary-text="lead.vehicleModel"
              @item-click="handleItemClick(lead)"
            >
              <template #prepend>
                <img
                  :src="getBrandLogo(lead.brand!)"
                  :alt="getNameBrand(lead.brand!).text"
                  class="mr-spacing-s h-6 w-6 rounded-full"
                />
              </template>
              <template #append>
                <s-chip :text="getRemainingTime(lead.expirationDate!)" small :color="colorChip" />
              </template>
            </s-list-item>
          </template>
        </div>
      </s-custom-list>
    </div>
  </div>
</template>

<script setup lang="ts">
import { SChip, SCustomList, SListItem, SProgressCircular } from 'sima-suite-components-vue3'
import { type IGetLeadsQueryResult } from 'lead-tray-api-ts'
import { useTranslator } from 'sima-suite-translator-vue3'
import { BRANDLOGOS, getRemainingTime } from '@/utils'

const { translate } = useTranslator()

type DashboardLeadListProps = {
  items: IGetLeadsQueryResult[]
  colorChip: string
  isLoading: boolean
}

const emit = defineEmits<{
  (e: 'itemClick', lead: IGetLeadsQueryResult): void
}>()

const handleItemClick = (lead: IGetLeadsQueryResult) => {
  emit('itemClick', lead)
}

const getOverlineText = (lead: IGetLeadsQueryResult) => {
  return lead.source
    ? `${lead.source} - ${lead.customerSalutationTitle} ${lead.customerFirstName} ${lead.customerLastName}`
    : `${lead.customerSalutationTitle} ${lead.customerFirstName} ${lead.customerLastName}`
}

const getBrandLogo = (brand: string) => {
  return BRANDLOGOS[brand]
}

const getNameBrand = (brand: string) => {
  return {
    text: translate(`App.CarBrand.${brand}`)
  }
}

withDefaults(defineProps<DashboardLeadListProps>(), {})
</script>

<style scoped></style>
