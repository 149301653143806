import { createRouter, createWebHashHistory, type Router } from 'vue-router'
import { DashboardView, CallbackView, AppView } from '@/views'
import { useDealersStore } from '@/store'
import { useAuthentication } from 'sima-suite-authentication'

declare global {
  interface Window {
    hideImage: any
  }
}

export const getRouter = (): Router => {
  return createRouter({
    history: createWebHashHistory(import.meta.env.BASE_URL),
    routes: [
      {
        path: '/',
        beforeEnter: async () => {
          const { isLoggedIn, signIn } = useAuthentication()
          const isUserLoggedIn = await isLoggedIn()
          if (isUserLoggedIn) {
            const dealersStore = useDealersStore()
            await dealersStore.getDealers()

            window.hideImage()

            return true
          }
          signIn()
          return false
        },
        component: AppView,
        children: [
          {
            path: '/',
            name: 'home',
            beforeEnter: () => {
              const dealersStore = useDealersStore()
              if (dealersStore.dealersList.length === 0) {
                return { name: 'errorView', params: { id: 'no-visibility-groups' } }
              }

              return true
            },
            component: DashboardView,
            meta: { gtm: 'home' }
          },
          {
            path: '/leads',
            name: 'leadList',
            component: () => import('../views/HomeView/HomeView.vue'),
            meta: { gtm: 'leadsList' }
          },
          {
            path: '/lead/:id',
            name: 'leadDetails',
            component: () => import('../views/LeadDetailsView/LeadDetailsView.vue'),
            meta: { gtm: 'leadDetails' }
          },
          {
            path: '/reports',
            name: 'reports',
            component: () => import('@/views/ReportsView/ReportsView.vue'),
            meta: { gtm: 'reports' }
          },
          {
            path: '/lead-not-found/:id',
            name: 'leadNotFound',
            component: () => import('../views/LeadNotFoundView/LeadNotFoundView.vue'),
            meta: { gtm: 'leadNotFound' }
          },
          {
            path: '/error/:id',
            name: 'errorView',
            component: () => import('../views/ErrorView/ErrorView.vue'),
            meta: { gtm: 'errorView' }
          },
          {
            path: '/:pathMatch(.*)*',
            name: 'errorNotFound',
            component: () => import('../views/ErrorView/ErrorView.vue'),
            meta: { gtm: 'errorView' }
          }
        ]
      },
      {
        path: '/callback',
        name: 'callback',
        component: CallbackView
      }
    ]
  })
}
