import { defineStore } from 'pinia'
import type { State } from '@/store/Reports/types'
import { type BrandsClient, type ReportsClient } from 'lead-tray-api-ts'
import { createARelevantMessage, createEmptyFilters } from '@/utils'
import { SAlertType } from 'sima-suite-components-vue3'
import { useTranslator } from 'sima-suite-translator-vue3'

let reportsClient: ReportsClient
export const initReportsClient = (client: ReportsClient) => {
  reportsClient = client
}

let brandsClient: BrandsClient
export const initBrandsClient = (client: BrandsClient) => {
  brandsClient = client
}

const { translate } = useTranslator()

export const useReportsStore = defineStore({
  id: 'reports',
  state: (): State => ({
    filters: createEmptyFilters(),
    brands: [],
    selectedBrands: [],
    createdLeads: 0,
    scheduledLeads: 0,
    startDate: new Date(),
    endDate: new Date(),
    leadsBySource: [],
    leadsByTopic: []
  }),
  actions: {
    async getBrands() {
      try {
        const { brands } = await brandsClient.get()
        this.brands = brands!
      } catch (error) {
        createARelevantMessage(translate('App.Common.ErrorMessage'), SAlertType.ERROR)
      }
    },
    async getReportsPerformance() {
      try {
        const { createdLeads, scheduledLeads, leadsBySource, leadsByTopic } =
          await reportsClient.getStaticticsReport(this.selectedBrands, this.startDate, this.endDate)

        this.createdLeads = createdLeads!
        this.scheduledLeads = scheduledLeads!
        this.leadsBySource = leadsBySource!
        this.leadsByTopic = leadsByTopic!
      } catch (error) {
        createARelevantMessage(translate('App.Common.ErrorMessage'), SAlertType.ERROR)
      }
    },
    setDates(startDate: Date, endDate: Date) {
      this.startDate = startDate
      this.endDate = endDate
    }
  },
  getters: {}
})
