import { type SLanguage } from 'sima-suite-components-vue3'

enum Locale {
  ES = 'es',
  EN = 'en'
}

enum Language {
  ES = 'Español',
  EN = 'English'
}

export type ES = { key: Locale.ES; value: Language.ES }
export type EN = { key: Locale.EN; value: Language.EN }

export type Languages = ES | EN

const defaultLanguage = { key: Locale.ES, value: Language.ES } as ES

export const appLanguage: SLanguage<Languages> = {
  options: [
    { key: Locale.ES, value: Language.ES },
    { key: Locale.EN, value: Language.EN }
  ],
  visibility: true,
  default: defaultLanguage
}
